import { ref } from 'vue';
import { useOscarClient } from '~/composables/useOscarClient';
import { defineStore } from 'pinia';

export const useBasketStore = defineStore('basket', () => {
    const basket = ref(null);
    const summary = ref(null);
    const cartModalInfo = ref({ show: false, message: false });

    const cartModals = reactive([
        {
            show: false,
            message: false,
            id: 'detail',
            product: null,
            quantity: null,
            price: null,
        },
        {
            show: false,
            message: false,
            id: 'common',
            product: null,
            quantity: null,
            price: null,
        },
    ]);
    const { locale } = useI18n();
    const loaded = ref(false);
    const cartModalTimeout = ref(null);
    const detailPage = ref(false);

    function hideLine(url) {
        lines.value = lines.value.map((l) => {
            if (l.url === url) {
                l.hide = true;
                basket.value.total_incl_tax = parseFloat(
                    basket.value.total_incl_tax - l.price_incl_tax,
                ).toFixed(2);
            }
            return l;
        });
    }

    const lines = computed(() => {
        return basket?.value?.lines;
    });

    const lineCache = {};

    const loading = ref(false);

    function getProductUrl(id) {
        return `/api/products/${id}/`;
    }

    const overallQuantityForProduct = computed(() => (productId) => {
        let res = 0;
        lines.value?.forEach((l) => {
            if (l?.product?.includes(`/products/${productId}/`)) {
                res += l.quantity;
            }
        });
        return res;
    });

    function simulateFrontendAddToBasket(product, quantity) {
        loading.value = true;
        if (product) {
            const id = product.id;
            const index = lines.value.findIndex(
                (line) => line?.productData?.id === id,
            );
            if (index !== -1) {
                lines.value[index].quantity += quantity;
                return;
            }
            try {
                lines.value.push({
                    productData: {
                        id: product.id,
                        image: product?.images?.length
                            ? product.images[0].src
                            : '',
                        title: product?.title,
                        info: product?.info,
                    },
                    quantity,
                    price: product?.price_incl_tax_excl_discounts,
                    availability: {},
                    fake: true,
                });
                return;
            } catch (e) {}
        }
        lines.value.push({ productData: {}, quantity, availability: {} });
    }

    function removeFakeLines() {
        if (lines && lines.value) {
            lines.value = lines.value.filter((line) => !line.fake);
        }
    }

    async function addToBasket(productId, quantity, product, price, id) {
        const oscar = useOscarClient(locale.value);
        simulateFrontendAddToBasket(product, quantity);
        return oscar
            .addProductToBasket(getProductUrl(productId), quantity)
            .then((res) => {
                displayAddMessage(product, price, quantity, id);
                updateBasket();
            });
    }

    async function updateLineInformation() {
        const oscar = useOscarClient(locale.value);
        return await Promise.all(
            basket.value.lines.map(async (line) => {
                line.unitPrice = parseFloat(
                    line.price_incl_tax_excl_discounts / line.quantity,
                ).toFixed(2);
                const product = await oscar.getProduct(line.product);
                lineCache[line.id] = product.data;
                line.productData = product.data;
                line.loading = false;
                line.availability = product.data.availability;
            }),
        );
    }

    async function setBasket(data) {
        basket.value = data;
        basket.value.initialPrice = basket.value.total_incl_tax;
        return await updateLineInformation();
    }

    async function updateBasket() {
        loading.value = true;
        const oscar = useOscarClient(locale.value);
        oscar
            .getBasket()
            .then((basketData) => {
                setBasket(basketData.data).then(() => {
                    loading.value = false;
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async function updateSummary(slot) {
        const oscar = useOscarClient(locale.value);
        oscar.getSummary(slot).then((summaryData) => {
            summary.value = summaryData.data;
        });
    }

    function setCartModal(id, attr, val) {
        for (let i = 0; i < cartModals.length; i++) {
            if (cartModals[i].id === id) {
                cartModals[i][attr] = val;
            }
        }
    }

    function cartModalVal(id, attr) {
        for (const modal of cartModals) {
            if (modal.id === id) {
                return modal[attr];
            }
        }
    }

    async function displayAddMessage(product, price, quantity, id) {
        clearTimeout(cartModalTimeout.value);
        const modal = cartModals.find((modal) => modal.id === id);
        modal.product = { title: product.title };
        modal.quantity = quantity;
        modal.price = price;
        modal.show = true;
        modal.message = true;
        if (id !== 'detail') {
            cartModalTimeout.value = setTimeout(() => {
                modal.show = false;
            }, 6000);
        } else {
            cartModalTimeout.value = setTimeout(() => {
                modal.message = false;
            }, 6000);
        }
    }

    watch(locale, () => {
        updateBasket();
    });

    return {
        loading,
        lines,
        basket,
        cartModalInfo,
        addToBasket,
        updateBasket,
        loaded,
        summary,
        updateSummary,
        hideLine,
        detailPage,
        cartModalTimeout,
        removeFakeLines,
        updateLineInformation,
        setBasket,
        setCartModal,
        cartModalVal,
        cartModals,
        overallQuantityForProduct,
    };
});
